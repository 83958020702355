import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';

import classes from './NoResult.module.scss';

const NoResult = ({ title, subtitle, sizes = {}, className }) => {
  const { icon = 80, title: titleFontSize = 16, subtitle: subtitleFontSize = 14 } = sizes;

  return (
    <div className={classnames(classes.parent, className)}>
      <SpriteIcon icon="not_found" size={icon} className={classes.searchIcon} />
      <Typography weight="bold" size={titleFontSize}>
        {title}
      </Typography>
      {subtitle && (
        <Typography size={subtitleFontSize} color="secondary">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default NoResult;
