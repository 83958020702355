import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import TextField from 'components/uiLibrary/TextField';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import classes from './SearchInput.module.scss';

const SearchInput = ({ onChange = () => {}, placeholder }) => {
  const [query, setQuery] = useState('');

  const onChangeHandler = useCallback(e => {
    setQuery(e.target.value);
  }, []);

  const onQueryChange = useMemo(() => debounce(onChange, 400), [onChange]);

  useEffect(() => {
    onQueryChange(query);
  }, [query, onQueryChange]);

  const onClearInput = () => {
    setQuery('');
  };

  return (
    <div className={classes.searchInput}>
      <TextField
        placeholder={placeholder}
        variant="outlined"
        size="small"
        value={query}
        onChange={onChangeHandler}
        className={classes.searchInput__field}
        fullWidth
      />
      {query?.length > 0 && (
        <>
          <SpriteIcon icon="close" className={classes.searchInput__close_icon} onClick={() => onClearInput()} />
          <span className={classes.searchInput__pipe_icon}>|</span>
        </>
      )}
      <SpriteIcon icon="search" className={classes.searchInput__icon} />
    </div>
  );
};

export default SearchInput;
